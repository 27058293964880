import type { SerializeFrom } from "@remix-run/node";
import { useRouteLoaderData } from "@remix-run/react";
import type { loader } from "~/root";
import type { loader as layoutLoader } from "~/routes/_layout";

export function useRootLoaderData() {
  return useRouteLoaderData<typeof loader>("root") as SerializeFrom<typeof loader>;
}

export function useUserLoaderData() {
  return useRouteLoaderData<typeof layoutLoader>("routes/_layout") as SerializeFrom<typeof layoutLoader>;
}
